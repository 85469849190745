import { render, staticRenderFns } from "./comunitiBdashboard.vue?vue&type=template&id=79d79b36&scoped=true"
import script from "./comunitiBdashboard.vue?vue&type=script&lang=js"
export * from "./comunitiBdashboard.vue?vue&type=script&lang=js"
import style0 from "./comunitiBdashboard.vue?vue&type=style&index=0&id=79d79b36&prod&scoped=true&lang=css"
import style1 from "./comunitiBdashboard.vue?vue&type=style&index=1&id=79d79b36&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d79b36",
  null
  
)

export default component.exports